import { z } from 'zod';

export const LocationId = z.string().brand('LocationId');
export const Location = z.object({
  locationId: LocationId,
  name: z.string(),
  timeZone: z.string(),
  description: z.string(),
  bannerURL: z.string().url(),
  businessHours: z.string(),
  phoneNumber: z.string(),
  regularHoliday: z.string(),
  address: z.string(),
  email: z.string().email().optional(),
  access: z.string(),
  ownersVoice: z.string(),
  googleMapPlaceId: z.string().optional(),
  createdAt: z.string().datetime({ offset: true }),
  updatedAt: z.string().datetime({ offset: true }),
});
export type LocationType = z.infer<typeof Location>;
